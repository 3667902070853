@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.font-sans {
  font-family: "Karla", sans-serif;
}
.font-serif {
  font-family: "Playfair Display", serif;
}

body {
  @apply bg-white font-sans font-normal text-neutral-600;
}
body a {
  @apply no-underline text-blue-700;
}
.gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
.text-shad {
  text-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}
.text-shad-wh {
  text-shadow: 0px 3px 1px 1px rgba(255, 255, 0255, 0.8);
}
nav .navbar-menu a {
  @apply font-sans font-medium tracking-wide;
}
/* nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; } */
body main p {
  @apply mb-4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  line-height: 1.1em !important;
  letter-spacing: 0;
}
h1 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h1.heading {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-2xl md:text-3xl lg:text-5xl mb-4;
}
h3 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h4 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h5 {
  @apply text-lg lg:text-xl mb-4;
}
h6 {
  @apply text-base md:text-lg mb-4;
}

.page-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.page-heading:before {
  @apply bg-fixed absolute bg-gradient-to-l to-blue-900 via-blue-800/70 from-transparent bg-opacity-15 bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

/* .slider-sec { background-image: linear-gradient(0deg, #171717 40%, transparent 35%), url(https://res.cloudinary.com/dlpitjizv/image/upload/f_auto/v1686680314/impact/Home_Hero_Background_D_f9a09d8c5f.jpg); background-position: center; }

.gradient-1 { background-image: linear-gradient(0deg, #171717 40%, transparent 35%); background-position: center;}
.gradient-2 { background-image: linear-gradient(0deg, transparent 40%, #171717 35%); background-position: center;} 
.gradient-ltr { background-image: linear-gradient(90deg, #e0e0e0 71%, #cb1313 32%); background-position: center;} **/

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 bg-indigo-950 bg-opacity-90 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.blue-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.blue-tint:before {
  @apply bg-fixed absolute bottom-0 bg-blue-900 bg-opacity-80 left-0 right-0 top-0 w-full overflow-hidden;
}

.black-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.black-tint:before {
  @apply bg-fixed absolute bottom-0 bg-black left-0 right-0 top-0 w-full overflow-hidden;
}

.white-tint {
  @apply bg-cover bg-no-repeat bg-left relative overflow-hidden w-full;
}
.white-tint:before {
  @apply bg-fixed absolute bottom-0 bg-gray-200 bg-opacity-90 left-0 right-0 top-0 w-full overflow-hidden;
}

.heading-vert {
  writing-mode: vertical-rl;
  rotate: 180deg;
}

.btn-theme {
  @apply bg-transparent hover:bg-red-600 border-2 border-white text-white font-serif font-bold uppercase;
}
.btn-white {
  @apply bg-white hover:bg-transparent border-2 border-white text-black hover:text-white font-serif font-bold uppercase;
}
.btn-red {
  @apply bg-red-600 hover:bg-red-700 border-2 border-red-600 text-white font-serif font-bold uppercase;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
